<template>
  <div class="pay-and-get-offer-payment">
    <div class="payment-and-card">
      <div class="payment-details">
        <h-subsection-header :aria-level="3" :text="$t('components.pay_and_get_offer_payment.detail_title')" class="mb-3" />
        <v-divider class="my-3 mt-6" />
        <div v-for="(x, index) in paymentDetails" :key="index">
          <payment-detail-item :text="$t(x.label_code)" :amount="x.amount" />
        </div>
        <v-divider class="my-3" />
        <payment-detail-use-my-money v-if="paymentMyMoney" />
        <v-divider v-if="paymentMyMoney" class="my-3" />
        <payment-detail-final :amount="paymentTotal" />
      </div>

      <h-form ref="form" class="card-details" v-model="formValid">
        <h-subsection-header :aria-level="3" :text="$t('components.pay_and_get_offer_payment.payment_method_title')" />

        <div v-if="my_cash_amount_max">
          <h3 class="h-label-3-sb payment-type-heading" v-html="$t('components.pay_and_get_offer_payment.heading_my_cash', { my_cash_amount_max: myCashAmountMaxFormatted })"></h3>

          <h-checkbox class="checkbox-my-cash" :disabled="working" top-align v-model="pay_with_my_cash">
            <template #label>
              <div v-html="$t('components.pay_and_get_offer_payment.pay_with_my_cash')"></div>
            </template>
          </h-checkbox>

          <p class="h-text-3 description-text" v-html="$t('components.pay_and_get_offer_payment.description', { offer_total: maxAmountFormatted })"></p>
        </div>

        <div v-if="pay_with_my_cash && my_cash_amount_max">
          <div class="my-cash-amount-wrap">
            <p class="my-cash-amount-heading h-label-1" role="heading" aria-level="4" v-html="$t('components.pay_and_get_offer_payment.my_cash_amount')"></p>
            <h-pay-and-get-editable-input-decimal class="my-cash-amount-input" v-model="my_cash_amount" :hint="$t('components.pay_and_get_offer_payment.hint', { min_amount: minAmount, max_amount: maxAmount })" :rules="() => myCashRules" />
          </div>

          <div class="credit-card-amount-wrap">
            <p class="credit-card-amount-heading h-label-1" role="heading" aria-level="4" v-html="$t('components.pay_and_get_offer_payment.credit_card_amount')"></p>
            <div class="h-heading-h1">{{ creditCardAmountFormatted }}</div>
          </div>

          <p class="h-text-3 description-text-open-purse-remainder" v-html="$t('components.pay_and_get_offer_payment.open_purse_remaining', { open_purse_new_total: openPurseRemainderFormatted })"></p>
        </div>

        <div v-if="!isCoveredByMyCash">
          <h3 class="h-label-3-sb payment-type-heading-credit-card">
            <span v-html="$t('components.pay_and_get_offer_payment.heading_credit_card')"></span>
            <div class="icon-container">
              <img src="@/assets/visa_transparent.svg" class="cc-img-2" />
              <img src="@/assets/mc_vrt_pos.svg" class="cc-img-2" />
              <img src="@/assets/amex.svg" class="cc-img-2" />
            </div>
          </h3>

          <payment-saved-cards ref="savedCard" :other-card-function="chooseAnotherCard" v-if="useSavedCard" />
          <payment-form-v2 v-if="useNewCard" ref="newCard" @readyToPay="setReadyToPay" :has-payment-methods="hasPaymentMethods" />
          <!-- <payment-form-v5 v-if="useNewCard" ref="newCard" :include-postal-code="false" @readyToPay="setReadyToPay" :has-payment-methods="hasPaymentMethods" /> -->
        </div>

        <h-checkbox
          v-if="requestConsentCommunication && (!membership || !membership.allow_communication)"
          :disabled="working"
          class="membership-checkbox mt-4 mb-4"
          top-align
          :label="$t('components.pay_and_get_offer_payment.allow_communication', { partner: $options.filters.translateI18nObject(space, 'branding.label') })"
          v-model="allowCommunication"
        />
        <hello-form-error class="mb-2" />

        <h-btn block :loading="working" :disabled="!canPay" @click="startPayment" class="mt-4">{{ $t('components.pay_and_get_offer_payment.pay') }}</h-btn>

        <bonus-offer-terms-and-conditions-modal :publisher-category-id="publisherCategoryId" />
      </h-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PaymentDetailFinal from './components/PaymentDetailFinal.vue';
import PaymentDetailItem from './components/PaymentDetailItem.vue';
import PaymentDetailUseMyMoney from './components/PaymentDetailUseMyMoney.vue';
import PaymentFormV2 from './components/PaymentFormV2.vue';
import PaymentSavedCards from './components/PaymentSavedCards.vue';
import safeExecute from '@/composables/safe-execute';
import HelloFormError from '../system-store/errors/HelloFormError.vue';
import _find from 'lodash/find';
import _round from 'lodash/round';
import _get from 'lodash/get';
import errorTypes from '@/utils/error/error-types';
import { crowFundingFeatureIsActive } from '@/composables/offer-helper';
import BonusOfferTermsAndConditionsModal from '@/components/offer-details/BonusOfferTermsAndConditionsModal';
import { recaptcha } from '@/mixins';
import { validateMinValue, validateMaxValue } from '../../composables/validations';

export default {
  props: {
    partner_id: {
      type: String,
    },
    cardholder_id: {
      type: String,
    },
    publisher_id: {
      type: String,
    },
    offer: {
      type: Object,
    },
    publisherCategoryId: {
      type: String,
    },
    validContext: {
      type: Boolean,
      default: false,
    },
    paymentInfo: {
      type: Object,
    },
    space: {
      type: Object,
    },
  },
  name: 'pay-and-get-offer-payment',
  setup(props, { root }) {
    const { executeForm, working } = safeExecute(root.$store);
    return {
      executeForm,
      working,
      validateMinValue,
      validateMaxValue,
    };
  },
  components: { PaymentDetailItem, PaymentDetailFinal, PaymentDetailUseMyMoney, PaymentSavedCards, HelloFormError, BonusOfferTermsAndConditionsModal, PaymentFormV2 },
  data() {
    return {
      minAmount: 0.01,
      chooseNewCard: false,
      allowCommunication: true,
      pay_with_my_cash: false,
      my_cash_amount: null,
      formValid: false,
    };
  },
  mixins: [recaptcha],
  watch: {
    pay_with_my_cash(newVal) {
      if (newVal) {
        this.my_cash_amount = this.maxAmount;
      } else {
        this.my_cash_amount = 0;
      }
    },
    paymentAmount() {
      this.my_cash_amount = Math.min(this.maxAmount, this.my_cash_amount);
      this.$refs.form.validate();
    },
  },
  computed: {
    ...mapGetters('payment', ['paymentDetails', 'paymentTotal', 'paymentAmount', 'hasPaymentMethods', 'creditCardInfoComplete', 'paymentMyMoney']),
    ...mapGetters('overlayPayment', ['my_cash_amount_max']),
    ...mapGetters('account', ['accountId', 'accountActiveMemberships']),
    ...mapGetters('tracking', ['trackingLinkId', 'visitorUuid']),
    myCashRules() {
      return [this.validateMinValue(this.minAmount), this.validateMaxValue(this.maxAmount)];
    },
    maxAmount() {
      return Math.min(this.paymentTotal, this.my_cash_amount_max);
    },
    creditCardAmountLeftToPay() {
      return Math.max(0, this.paymentTotal - this.my_cash_amount);
    },
    requestConsentCommunication() {
      return _get(this.space, 'request_consent_communication', false);
    },
    maxAmountFormatted() {
      return this.$options.filters.currencySymbol(this.$options.filters.currency_full(this.maxAmount));
    },
    myCashAmountMaxFormatted() {
      return this.$options.filters.currencySymbol(this.$options.filters.currency_full(this.my_cash_amount_max));
    },
    openPurseRemainderFormatted() {
      const openPurseRemainder = Math.max(0, this.my_cash_amount_max - this.my_cash_amount);
      return this.$options.filters.currencySymbol(this.$options.filters.currency_full(openPurseRemainder));
    },
    creditCardAmountFormatted() {
      return this.$options.filters.currencySymbol(this.$options.filters.currency_full(this.creditCardAmountLeftToPay));
    },
    isCoveredByMyCash() {
      return this.creditCardAmountLeftToPay === 0;
    },
    membership() {
      return _find(this.accountActiveMemberships, (activeMembership) => activeMembership.partner_id === this.partner_id);
    },
    paymentExceedMaxPaymentBecauseOfUserRestriction() {
      return this.paymentAmount > _get(this.paymentInfo, 'user_payment_remaining');
    },
    useSavedCard() {
      return this.hasPaymentMethods && !this.chooseNewCard;
    },
    useNewCard() {
      return this.chooseNewCard || !this.hasPaymentMethods;
    },
    paymentTrackingPayload() {
      return {
        partner_id: this.partner_id,
        offer_id: this.offer.id,
        category_id: this.offer.category_id,
        payment: this.paymentAmount,
        voucher: _round((1 + _get(this.offer, 'incentive.bonus_percent', 0)) * this.paymentAmount, 2),
      };
    },
    canPay() {
      return ((this.validContext && this.creditCardInfoComplete) || this.isCoveredByMyCash) && this.formValid && !this.paymentExceedMaxPaymentBecauseOfUserRestriction;
    },
  },
  methods: {
    ...mapActions('payment', ['resetPaymentWorking', 'setReadyToPay']),
    chooseAnotherCard() {
      this.chooseNewCard = true;
    },
    chooseSavedCard() {
      this.chooseNewCard = false;
    },
    async startPayment() {
      await this.pay();
    },
    onError(error) {
      if (error instanceof errorTypes.AppError) {
        rg4js('send', {
          error,
          customData: {
            source: 'payment-error',
            errorCode: _get(error, 'custom_data.code', error.code),
            errorDescription: _get(error, 'custom_data.message', error.msg),
            paymentDetails: this.paymentTrackingPayload,
            accountId: this.accountId,
            errorData: _get(error, 'custom_data'),
          },
        });
      }
      this.resetPaymentWorking();
    },
    async pay() {
      const _this = this;
      let payload = {
        cardholder_id: this.cardholder_id,
        partner_id: this.partner_id,
        offer_id: this.offer.id,
        publisher_id: this.publisher_id,
        offerHasCrowdfundedFeature: crowFundingFeatureIsActive(this.offer),
        allow_communication: this.allowCommunication,
        my_cash_amount: this.my_cash_amount,
        credit_card_amount: this.creditCardAmountLeftToPay,
      };

      if (this.trackingLinkId && this.visitorUuid) {
        payload.tracking_link_id = this.trackingLinkId;
        payload.visitor_uuid = this.visitorUuid;
      }

      await this.executeForm(
        {
          beforeAction: async () => {
            payload.recaptcha_token = await this.recaptcha('offerPayment');
            if (this.useSavedCard) {
              payload.payment_method_id = this.$refs.savedCard.getPaymentMethod();
            } else if (this.useNewCard) {
              if (this.$refs.newCard) {
                const { token, merchantRefNum, postal_code } = await this.$refs.newCard.submit(this.creditCardAmountLeftToPay);
                payload.payment_method_token = token;
                payload.merchant_ref_number = merchantRefNum;
                payload.postal_code = postal_code;
              } else {
                payload.payment_method_token = null;
              }
            }
          },
          name: 'pay',
          action: 'overlayPayment/pay',
          beforeError: (error) => this.onError(error),
          success: () => {
            this.resetPaymentWorking();
            if (window.fbq) {
              fbq('trackCustom', 'PurchaseBonusOffer', this.paymentTrackingPayload);
            }
            _this.$emit('completed');
          },
        },
        payload,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-and-card {
  @media ($md-min) {
    display: flex;
  }
}

.payment-details {
  @media ($md-min) {
    width: 50%;
    padding-right: var(--gap);
  }
}

.card-details {
  position: relative;
  @media ($md-min) {
    width: 50%;
    padding-left: var(--gap);
  }
}

.membership-checkbox {
  padding-bottom: 8px;
}

.payment-type-heading {
  background-color: #f2f2f2;
  text-transform: uppercase;
  padding: 10px;
  margin-bottom: 12px;
}

.payment-type-heading-credit-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
  text-transform: uppercase;
  padding: 10px;
  margin: 12px 0;
}

::v-deep .payment-type-heading span {
  float: right;
}

.checkbox-my-cash {
  margin-bottom: 12px;
}

.description-text,
.description-text-open-purse-remainder {
  color: var(--color-text-secondary);
  padding-bottom: 12px;
}

.my-cash-amount-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}

.my-cash-amount-heading {
  margin-bottom: 20px;
}

.credit-card-amount-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.credit-card-amount-heading {
  margin-bottom: 0;
  line-height: 1.4;
}

.icon-container {
  display: flex;
  align-items: center;
}

.cc-img-2 {
  max-height: 20px;
  max-width: 56px;
  margin-left: 12px;
}

// Used to hide the label
::v-deep .my-cash-amount-input .v-text-field {
  padding-top: 0;
}

// Used to right-align the hint
::v-deep .my-cash-amount-input .v-messages {
  padding-right: 0;
  text-align: right;
}

// Used to vertically center the amount
::v-deep .my-cash-amount-input .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  align-items: center;
}

// Used to right-align error text
::v-deep .my-cash-amount-input .v-text-field__details .v-messages.theme--light.error--text {
  padding: 0;
  text-align: right;
}
</style>
