<template>
  <div class="consumer-gift-card-payment-form">
    <h-form ref="form" v-model="formValid">
      <h-subsection-header :aria-level="3" :text="$t('components.consumer_gift_card_payment_form.payment_method_title')" />

      <div v-if="my_cash_amount_max">
        <h3 class="h-label-3-sb payment-type-heading" v-html="$t('components.consumer_gift_card_payment_form.heading_my_cash', { my_cash_amount_max: myCashAmountMaxFormatted })"></h3>

        <h-checkbox class="checkbox-my-cash" :disabled="working" top-align v-model="pay_with_my_cash">
          <template #label>
            <div v-html="$t('components.consumer_gift_card_payment_form.pay_with_my_cash')"></div>
          </template>
        </h-checkbox>

        <p class="h-text-3 description-text" v-html="$t('components.consumer_gift_card_payment_form.description', { offer_total: maxAmountFormatted })"></p>
      </div>

      <div v-if="pay_with_my_cash">
        <div class="my-cash-amount-wrap">
          <p class="my-cash-amount-heading h-label-1" role="heading" aria-level="4" v-html="$t('components.consumer_gift_card_payment_form.my_cash_amount')"></p>
          <h-pay-and-get-editable-input-decimal class="my-cash-amount-input" v-model="my_cash_amount" :hint="$t('components.consumer_gift_card_payment_form.hint', { min_amount: minAmount, max_amount: maxAmount })" :rules="() => myCashRules" />
        </div>

        <div class="credit-card-amount-wrap">
          <p class="credit-card-amount-heading h-label-1" role="heading" aria-level="4" v-html="$t('components.consumer_gift_card_payment_form.credit_card_amount')"></p>
          <div class="h-heading-h1">{{ creditCardAmountFormatted }}</div>
        </div>

        <p class="h-text-3 description-text-open-purse-remainder" v-html="$t('components.consumer_gift_card_payment_form.open_purse_remaining', { open_purse_new_total: openPurseRemainderFormatted })"></p>
      </div>

      <div v-if="!isCoveredByMyCash">
        <h3 class="h-label-3-sb payment-type-heading-credit-card">
          <span v-html="$t('components.pay_and_get_offer_payment.heading_credit_card')"></span>
          <div class="icon-container">
            <img src="@/assets/visa_transparent.svg" class="cc-img-2" alt="visa logo" />
            <img src="@/assets/mc_vrt_pos.svg" class="cc-img-2" alt="mastercard logo" />
            <img src="@/assets/amex.svg" class="cc-img-2" alt="amex logo" />
          </div>
        </h3>

        <payment-form-v2 v-if="hasAContextAndContextIsReady" ref="newCard" :has-payment-methods="false" @readyToPay="(val) => (manuelCardIsReadyToPay = val)" />
      </div>

      <h-checkbox
        v-if="requestConsentCommunication && (!membership || !membership.allow_communication)"
        :disabled="working"
        class="mt-4 mb-4"
        top-align
        :label="$t('components.consumer_gift_card_payment_form.allow_communication', { partner: $options.filters.translateI18nObject(space, 'branding.label') })"
        v-model="allowCommunication"
      />

      <hello-form-error class="mb-2" />

      <div class="btn-container">
        <h-btn block @click="startPayment" :disabled="!readyToPay" :loading="working">{{ $t('components.consumer_gift_card_payment_form.cta_pay') }}</h-btn>
      </div>

      <consumer-gift-card-terms-and-conditions-modal />
    </h-form>
  </div>
</template>

<script>
import { recaptcha } from '@/mixins';
import _find from 'lodash/find';
import PaymentFormV2 from '@/components/pay-and-get/components/PaymentFormV2';
import safeExecute from '@/composables/safe-execute';
import { mapGetters } from 'vuex';
import HelloFormError from '@/components/system-store/errors/HelloFormError.vue';
import ConsumerGiftCardTermsAndConditionsModal from '@/components/consumer-gift-card/components/ConsumerGiftCardTermsAndConditionsModal.vue';
import { validateMinValue, validateMaxValue } from '../../../composables/validations';
import _get from 'lodash/get';
import _min from 'lodash/min';

export default {
  name: 'consumer-gift-card-payment-form',
  setup(props, { root }) {
    const { executeForm, working } = safeExecute(root.$store);
    return {
      executeForm,
      working,
      validateMinValue,
      validateMaxValue,
    };
  },
  props: {
    partnerId: {
      type: String,
    },
    offerId: {
      type: String,
    },
    space: {
      type: Object,
    },
  },
  data() {
    return {
      minAmount: 0.01,
      manuelCardIsReadyToPay: false,
      chooseNewCard: false,
      allowCommunication: true,
      pay_with_my_cash: false,
      my_cash_amount: null,
      formValid: false,
    };
  },
  mixins: [recaptcha],
  components: {
    HelloFormError,
    PaymentFormV2,
    ConsumerGiftCardTermsAndConditionsModal,
  },
  watch: {
    pay_with_my_cash(newVal) {
      if (newVal) {
        this.my_cash_amount = this.maxAmount;
      } else {
        this.my_cash_amount = 0;
      }
    },
    maxAmount() {
      this.my_cash_amount = _min(this.my_cash_amount, this.maxAmount) || 0;
    },
  },
  computed: {
    ...mapGetters('account', ['accountActiveMemberships']),
    ...mapGetters('payment', ['hasPaymentMethods']),
    ...mapGetters('consumerGiftCard', ['amountAndMessageAreValid', 'paymentAmount', 'hasAContextAndContextIsReady', 'my_cash_amount_max']),
    ...mapGetters('tracking', ['trackingLinkId', 'visitorUuid']),
    requestConsentCommunication() {
      return _get(this.space, 'request_consent_communication', false);
    },
    myCashRules() {
      if (!this.my_cash_amount_max) {
        return [];
      }
      return [this.validateMinValue(this.minAmount), this.validateMaxValue(this.maxAmount)];
    },
    maxAmount() {
      return this.paymentAmount < this.my_cash_amount_max ? this.paymentAmount : this.my_cash_amount_max;
    },
    maxAmountFormatted() {
      return this.$options.filters.currencySymbol(this.$options.filters.currency_full(this.maxAmount));
    },
    myCashAmountMaxFormatted() {
      return this.$options.filters.currencySymbol(this.$options.filters.currency_full(this.my_cash_amount_max));
    },
    myCashAmountMaxMinusMyCashAmount() {
      return this.my_cash_amount_max - this.my_cash_amount;
    },
    openPurseRemainderFormatted() {
      if (this.myCashAmountMaxMinusMyCashAmount > 0) {
        return this.$options.filters.currencySymbol(this.$options.filters.currency_full(this.myCashAmountMaxMinusMyCashAmount));
      }
      return this.$options.filters.currencySymbol(this.$options.filters.currency_full(0));
    },
    creditCardAmountLeftToPay() {
      return Math.max(0, this.paymentAmount - this.my_cash_amount);
    },
    offerTotalMinusMyCashAmount() {
      return this.paymentAmount - this.my_cash_amount;
    },
    creditCardAmountFormatted() {
      if (this.offerTotalMinusMyCashAmount > 0) {
        return this.$options.filters.currencySymbol(this.$options.filters.currency_full(this.offerTotalMinusMyCashAmount));
      }
      return this.$options.filters.currencySymbol(this.$options.filters.currency_full(0));
    },
    isCoveredByMyCash() {
      return this.my_cash_amount && this.my_cash_amount >= this.paymentAmount && this.my_cash_amount <= this.my_cash_amount_max && this.creditCardAmountLeftToPay === 0;
    },
    useSavedCard() {
      return this.hasPaymentMethods && !this.chooseNewCard && !this.isCoveredByMyCash;
    },
    useNewCard() {
      return this.chooseNewCard || (!this.hasPaymentMethods && !this.isCoveredByMyCash);
    },
    cardIsReadyToPay() {
      return this.useSavedCard || this.manuelCardIsReadyToPay;
    },
    readyToPay() {
      return this.amountAndMessageAreValid && (this.cardIsReadyToPay || this.isCoveredByMyCash) && this.hasAContextAndContextIsReady && this.formValid;
    },
    membership() {
      return _find(this.accountActiveMemberships, (activeMembership) => activeMembership.partner_id === this.partnerId);
    },
  },
  methods: {
    chooseAnotherCard() {
      this.chooseNewCard = true;
    },
    chooseSavedCard() {
      this.chooseNewCard = false;
    },
    async startPayment() {
      await this.pay();
    },

    async pay() {
      const _this = this;

      let payload = {
        offer_id: this.offerId,
        partner_id: this.partnerId,
        allow_communication: this.allowCommunication,
        my_cash_amount: this.my_cash_amount,
        credit_card_amount: this.offerTotalMinusMyCashAmount,
      };

      if (this.trackingLinkId && this.visitorUuid) {
        payload.tracking_link_id = this.trackingLinkId;
        payload.visitor_uuid = this.visitorUuid;
      }

      await this.executeForm(
        {
          beforeAction: async () => {
            payload.recaptcha_token = await this.recaptcha('consumerGiftCardPurchase');
            if (this.useSavedCard) {
              payload.payment_method_id = this.getSavedPaymentMethod();
            } else if (this.useNewCard) {
              if (this.$refs.newCard) {
                const { token, merchantRefNum, postal_code } = await this.$refs.newCard.submit(this.offerTotalMinusMyCashAmount);
                payload.payment_method_token = token;
                payload.merchant_ref_number = merchantRefNum;
                payload.postal_code = postal_code;
              } else {
                payload.payment_method_token = null;
              }
            }
          },
          name: 'pay',
          action: 'consumerGiftCard/pay',
          success: () => {
            if (window.fbq) {
              fbq('trackCustom', 'PurchaseConsumerGiftCard', this.paymentTrackingPayload);
            }
            _this.$emit('completed');
          },
        },
        payload,
      );
    },
    paymentTrackingPayload() {
      return {
        partner_id: this.partnerId,
        offer_id: this.offerId,
        payment: this.paymentAmount,
      };
    },
    getSavedPaymentMethod() {
      return this.$refs.savedCard.getPaymentMethod();
    },
    getPaymentMethod() {
      return this.$refs.newCard.getPaymentMethod();
    },
    getPaymentMethodZip() {
      return this.$refs.newCard.getPaymentMethodZip();
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-type-heading {
  background-color: #f2f2f2;
  text-transform: uppercase;
  padding: 10px;
  margin-bottom: 12px;
}

.payment-type-heading-credit-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
  text-transform: uppercase;
  padding: 10px;
  margin: 12px 0;
}

::v-deep .payment-type-heading span {
  float: right;
}

.checkbox-my-cash {
  margin-bottom: 12px;
}

.description-text,
.description-text-open-purse-remainder {
  color: var(--color-text-secondary);
  padding-bottom: 12px;
}

.my-cash-amount-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}

.credit-card-amount-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.my-cash-amount-heading {
  margin-bottom: 20px;
}

.credit-card-amount-heading {
  margin-bottom: 0;
  line-height: 1.4;
}

.icon-container {
  display: flex;
  align-items: center;
}

.cc-img {
  max-height: 40px;
  max-width: 56px;
}

.cc-img-2 {
  max-height: 20px;
  max-width: 56px;
  margin-left: 12px;
}

// Used to hide the label
::v-deep .my-cash-amount-input .v-text-field {
  padding-top: 0;
}

// Used to right-align the hint
::v-deep .my-cash-amount-input .v-messages {
  padding-right: 0;
  text-align: right;
}

// Used to vertically center the amount
::v-deep .my-cash-amount-input .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  align-items: center;
}

// Used to right-align error text
::v-deep .my-cash-amount-input .v-text-field__details .v-messages.theme--light.error--text {
  padding: 0;
  text-align: right;
}
</style>
